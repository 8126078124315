import React, { useCallback } from "react";
import cx from "classnames";

import s from "./SlidingMessage.module.scss";
import { Stars, Theme as StarsTheme } from "./Stars";
import {
	shareNatively,
	shareViaClipboard,
	shouldUseAppleShareIcon,
	shouldUseNativeShareForText,
} from "../utils/ShareUtils";
import MaterialIcons from "./MaterialIcons";

export type TProps = {
	message?: string;
	isVisible?: boolean;
	shareText?: string;
	onAfterShare?: (usedClipboard: boolean) => void;
	showStars?: boolean;
	starsMax?: number;
	starsLeft?: number;
};

const ShareIcon = ({ className }: { className?: string }): JSX.Element | null => {
	if (shouldUseNativeShareForText()) {
		if (shouldUseAppleShareIcon()) {
			return <MaterialIcons.ShareIos className={className} />;
		} else {
			return <MaterialIcons.Share className={className} />;
		}
	} else {
		return <MaterialIcons.CopyToClipboard className={className} />;
	}
};

export const SlidingMessage = ({
	message,
	isVisible,
	shareText,
	onAfterShare,
	showStars,
	starsMax,
	starsLeft,
}: TProps): JSX.Element | null => {
	const classes = cx(s.main, isVisible ? undefined : s.hidden);

	const handleClickShare = useCallback(() => {
		if (shareText) {
			if (shouldUseNativeShareForText()) {
				shareNatively(shareText).then(() => {
					onAfterShare?.(false);
				});
			} else {
				shareViaClipboard(shareText);
				onAfterShare?.(true);
			}
		}
	}, [shareText, onAfterShare]);

	return (
		<div className={classes}>
			<div className={s.message}>{message}</div>
			{showStars ? <Stars max={starsMax ?? 0} left={starsLeft ?? 0} theme={StarsTheme.CurrentColor} /> : null}
			<button className={s.shareButton} onClick={handleClickShare}>
				<ShareIcon className={s.icon} />
				<div className={s.text}>SHARE</div>
			</button>
		</div>
	);
};
