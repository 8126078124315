import React from "react";
import cx from "classnames";

import { SymbolShape, TSymbolStyle } from "../hooks/useSymbols";

import s from "./Symbol.module.scss";

export type TProps = {
	style: TSymbolStyle;
};

export const Symbol = ({ style }: TProps): JSX.Element => {
	const { color, shape } = style;
	const classes = cx(
		s.main,
		shape === SymbolShape.Circle ? s.shapeCircle : undefined,
		shape === SymbolShape.Square ? s.shapeSquare : undefined,
	);

	return <div className={classes} style={{ background: color }} />;
};
