import React, { useEffect } from "react";

import { COLORS } from "../hooks/useSymbols";

export type TProps = {
	className?: string;
	textColor?: string;
};

import s from "./Logo.module.scss";

export const Logo = ({ className, textColor }: TProps): JSX.Element => {
	const fullTextColor = textColor ?? "#5c6574";
	useEffect(() => {
		COLORS.forEach((c, i) => document.documentElement.style.setProperty(`--symbol-color-${i}`, c));
	}, []);

	return (
		<svg viewBox="0 0 968 79" className={className}>
			<rect fill={fullTextColor} x="0" y="2.91" width="19.07" height="72.07" />
			<path
				fill={fullTextColor}
				d="M79.28,39.5C79.28,15.33 95.91,1.58 114.09,1.58C123.62,1.58 131.39,6.02 136.49,11.12L126.51,23.32C122.96,20.22 119.41,18 114.53,18C106.1,18 98.79,25.76 98.79,38.84C98.79,51.92 105,59.9 114.31,59.9C119.85,59.9 124.29,57.02 127.39,53.69L137.37,65.67C131.27,72.77 122.96,76.31 113.64,76.31C95.23,76.31 79.27,64.11 79.27,39.5L79.28,39.5Z"
			/>
			<circle className={s.symbolColor} cx="220.64" cy="39.2" r="39.2" />
			<path
				fill={fullTextColor}
				d="M314.33,2.91L333.84,2.91L349.81,35.06L356.68,51.25L357.12,51.25C356.23,43.49 354.68,32.62 354.68,23.76L354.68,2.91L372.86,2.91L372.86,74.98L353.35,74.98L337.38,42.72L330.51,26.64L330.07,26.64C330.96,34.84 332.51,45.26 332.51,54.13L332.51,74.97L314.33,74.97L314.33,2.91Z"
			/>
			<path
				fill={fullTextColor}
				d="M436.18,2.91L483.19,2.91L483.19,18.87L455.25,18.87L455.25,32.62L479.2,32.62L479.2,48.59L455.25,48.59L455.25,74.98L436.18,74.98L436.18,2.91Z"
			/>
			<circle className={s.symbolColor} cx="573.7" cy="39.2" r="39.2" />
			<path
				fill={fullTextColor}
				d="M665.47,40.17L665.47,2.91L684.54,2.91L684.54,42.38C684.54,55.24 687.87,59.9 695.41,59.9C702.95,59.9 706.5,55.24 706.5,42.38L706.5,2.91L724.9,2.91L724.9,40.17C724.9,64.78 715.59,76.32 695.41,76.32C675.23,76.32 665.48,64.79 665.48,40.17L665.47,40.17Z"
			/>
			<path
				fill={fullTextColor}
				d="M787.77,2.91L807.28,2.91L823.25,35.06L830.12,51.25L830.56,51.25C829.67,43.49 828.12,32.62 828.12,23.76L828.12,2.91L846.3,2.91L846.3,74.98L826.79,74.98L810.82,42.72L803.95,26.64L803.51,26.64C804.4,34.84 805.95,45.26 805.95,54.13L805.95,74.97L787.77,74.97L787.77,2.91Z"
			/>
			<path
				fill={fullTextColor}
				d="M909.62,2.91L930.91,2.91C952.86,2.91 967.94,13.11 967.94,38.61C967.94,64.11 952.86,74.98 932.02,74.98L909.62,74.98L909.62,2.91ZM929.8,59.68C940.22,59.68 948.42,55.46 948.42,38.61C948.42,21.76 940.22,18.21 929.8,18.21L928.69,18.21L928.69,59.68L929.8,59.68Z"
			/>
		</svg>
	);
};
