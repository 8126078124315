import Prando from "prando";

export const shuffle = <T>(rng: Prando, list: T[], maxLength: number = NaN): T[] => {
	const newList: T[] = [];
	const oldList = list.concat();
	while (oldList.length > 0 && (isNaN(maxLength) || newList.length < maxLength)) {
		const i = rng.nextInt(0, oldList.length - 1);
		newList.push(oldList[i]);
		oldList.splice(i, 1);
	}
	return newList;
};
