const VOWELS = "aeiouy";
const CONSONANTS = "bcdfghjklmnpqrstvwxz";

export const isVowel = (letter: string): boolean => {
	return VOWELS.includes(letter);
};

export const isConsonant = (letter: string): boolean => {
	return CONSONANTS.includes(letter);
};

export const areLettersCompatible = (letterA: string, letterB: string): boolean => {
	return isVowel(letterA) === isVowel(letterB) && isConsonant(letterA) === isConsonant(letterB);
};

export const uniqueStrings = (strings: string[]): string[] => {
	return strings.filter((l, i, self) => self.indexOf(l) == i);
};

export const uniqueLetters = (words: string[]): string[] => {
	return uniqueStrings(words.join("").split(""));
};
