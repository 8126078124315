import React from "react";

import { FieldRow } from "./FieldRow";
import { TSymbolSolutionField, TSymbolStyleMap } from "../hooks/useSymbols";
import { TUseGuesses } from "../hooks/useGuesses";

import s from "./Field.module.scss";

export type TProps = {
	disabled?: boolean;
	getLetterForSymbol: (symbol: string) => string | null;
	symbols: TSymbolSolutionField;
	symbolStyles: TSymbolStyleMap;
	words: string[];
	reveal?: boolean;
	guesses: Readonly<TUseGuesses>;
	currentPosition?: { row: number; col: number };
	currentSymbol?: string;
	onPressCell?: (row: number, col: number) => void;
};

export const Field = ({
	disabled,
	getLetterForSymbol,
	symbols,
	symbolStyles,
	words,
	reveal,
	guesses,
	currentPosition,
	currentSymbol,
	onPressCell,
}: TProps): JSX.Element => {
	return (
		<div className={s.main}>
			{symbols.map((symbolRow, row) => (
				<FieldRow
					key={`row-${row}`}
					disabled={disabled}
					getLetterForSymbol={getLetterForSymbol}
					symbols={symbolRow}
					symbolStyles={symbolStyles}
					word={words[row]}
					reveal={reveal}
					guesses={guesses}
					currentPosition={currentPosition?.row === row ? currentPosition.col : undefined}
					currentSymbol={currentSymbol}
					onPress={(col) => onPressCell?.(row, col)}
				/>
			))}
		</div>
	);
};
