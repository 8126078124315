import React from "react";
import cx from "classnames";

import { MenuIcon } from "./MenuIcon";
import { Logo } from "./Logo";

import s from "./MenuBar.module.scss";

export type TProps = {
	onClickMenuIcon?: () => void;
};

export const MenuBar = ({ onClickMenuIcon }: TProps): JSX.Element => {
	return (
		<div className={s.main}>
			<div className={cx(s.iconSpacer, s.iconSpacerLink)} onClick={onClickMenuIcon}>
				<MenuIcon className={s.menuIcon} margin={0} />
			</div>
			<div className={s.logoArea}>
				<Logo />
			</div>
			<div className={s.iconSpacer} />
		</div>
	);
};
