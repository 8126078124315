import React from "react";

export type TProps = {
	className?: string;
	margin?: number;
	iconSize?: number;
	strokeWidth?: number;
};

export const MenuIcon = ({ className, margin, iconSize: size, strokeWidth }: TProps): JSX.Element => {
	const fullMargin = margin ?? 4;
	const fullIconSize = size ?? 16;
	const fullAreaSize = fullIconSize + fullMargin * 2;
	const fullStrokeWidth = strokeWidth ?? fullIconSize * 0.125; // 2
	const rows = [2, 8, 14].map((r) => (r / 16) * fullIconSize + fullMargin);
	const col1 = fullMargin;
	const col2 = fullAreaSize - fullMargin;
	return (
		<svg viewBox={`0 0 ${fullAreaSize} ${fullAreaSize}`} fill="none" className={className}>
			{rows.map((r, i) => (
				<path
					key={`l-${i}`}
					d={`M${col1} ${r}L${col2} ${r}`}
					stroke="currentColor"
					strokeWidth={fullStrokeWidth}
					strokeLinecap="round"
				/>
			))}
		</svg>
	);
};
