import React from "react";

import s from "./Header.module.scss";

export type TProps = {
	index: number;
	title: string;
};

export const Header = ({ index, title }: TProps): JSX.Element => {
	return (
		<div className={s.main}>
			<div className={s.index}>{`Puzzle ${index + 1}`}</div>
			<div className={s.title}>{title}</div>
		</div>
	);
};
