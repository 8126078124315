import React from "react";
import cx from "classnames";

import { TUseScore } from "../hooks/useScore";

import s from "./TextArchive.module.scss";

export type TProps = {
	className?: string;
	generalScore: TUseScore["general"];
	archiveScore: TUseScore["archive"];
	onClickDate?: (dayOffset: number) => void;
};

const formatPercentage = (pc: number, total: number): string => {
	const vf = (pc / total) * 100;
	const vi = vf > 0 && vf < 1 ? 1 : Math.round(vf);
	return `${vi}%`;
};

const stars = (count: number): string => {
	return "⭐⭐⭐".substring(0, count).split("").join(" ");
};

export const TextArchive = ({ className, generalScore, archiveScore, onClickDate }: TProps): JSX.Element => {
	const pc = (v: number): string => formatPercentage(v, generalScore.totalGames);
	return (
		<div className={className}>
			<table className={s.table}>
				<tbody>
					<tr>
						<td>Games</td>
						<td>{generalScore.totalGames}</td>
					</tr>
					<tr>
						<td>Won</td>
						<td>
							{generalScore.gamesWon} ({pc(generalScore.gamesWon)})
						</td>
					</tr>
					<tr>
						<td>{stars(3)}</td>
						<td>
							{generalScore.gamesWon3Stars} ({pc(generalScore.gamesWon3Stars)})
						</td>
					</tr>
					<tr>
						<td>{stars(2)}</td>
						<td>
							{generalScore.gamesWon2Stars} ({pc(generalScore.gamesWon2Stars)})
						</td>
					</tr>
					<tr>
						<td>{stars(1)}</td>
						<td>
							{generalScore.gamesWon1Stars} ({pc(generalScore.gamesWon1Stars)})
						</td>
					</tr>
					<tr>
						<td>💀</td>
						<td>
							{generalScore.gamesLost} ({pc(generalScore.gamesLost)})
						</td>
					</tr>
					<tr>
						<td>Current streak</td>
						<td>
							{generalScore.currentWinStreak} ({pc(generalScore.gamesWon3Stars)})
						</td>
					</tr>
					<tr>
						<td>Best streak</td>
						<td>
							{generalScore.bestWinStreak} ({pc(generalScore.gamesWon3Stars)})
						</td>
					</tr>
				</tbody>
			</table>
			<p>Game Data</p>
			<div className={s.archive}>
				{archiveScore.map((day) => {
					const classNames = cx({
						[s.row]: true,
						[s.won]: day.hasWon,
						[s.lost]: day.hasLost,
					});
					return (
						<div key={day.id} className={classNames} onClick={() => onClickDate?.(day.offset)}>
							<div className={s.left}>
								<p className={s.index}>#{day.index + 1}</p>
								<p className={s.date}>{day.date.toLocaleDateString()}</p>
							</div>
							<div className={s.right}>
								{day.hasWon ? stars(day.chancesLeft) : null}
								{day.hasLost ? "💀" : null}
								{!day.hasWon && !day.hasLost ? "Not played" : null}
								<span className={s.spacer} />
								{day.hintsUsed ? <span className={s.tag}>HINT</span> : null}
								{day.hasWon && !day.hasWonOnGameDay ? <span className={s.tag}>ARCHIVE</span> : null}
								{day.offset === 0 ? <span className={s.tag}>TODAY</span> : null}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
