import { useMemo } from "react";
import { KeyboardKey, QwertyKeyboard, keyToLabelTry, keyToLetterTry } from "../data/KeyboardLayout";

type TKeyInfo = {
	key: KeyboardKey;
	letter: string | null;
	label: string | null;
	isDisabled: boolean;
};

type TKeyInfoMap = Record<KeyboardKey, TKeyInfo>;

export type TUseKeyboardLayout = {
	rows: TKeyInfo[][];
	keys: TKeyInfoMap;
	disabled: boolean;
};

const KEYS = QwertyKeyboard;
const ALL_KEYS = Object.values(KeyboardKey);

/**
 * Return the current layout and other state information of the keyboard
 */
const useKeyboardLayout = (
	disabled: boolean,
	lettersDisabled: boolean,
	disabledBackspace: boolean,
	usedLetters: readonly string[],
): Readonly<TUseKeyboardLayout> => {
	return useMemo<TUseKeyboardLayout>(() => {
		const keyList = ALL_KEYS.map((key): TKeyInfo => {
			const letter = keyToLetterTry(key);
			const label = keyToLabelTry(key);
			const isKeyDisabled =
				disabled ||
				(lettersDisabled && Boolean(letter)) ||
				Boolean((letter && usedLetters.includes(letter)) || (disabledBackspace && key === KeyboardKey.Backspace));
			return {
				key,
				letter,
				label,
				isDisabled: isKeyDisabled,
			};
		});
		const keys = Object.fromEntries(keyList.map((keyInfo) => [keyInfo.key, keyInfo])) as TKeyInfoMap;

		return {
			disabled,
			keys,
			rows: KEYS.rows.map((row): TKeyInfo[] => row.map((key) => keys[key])),
		};
	}, [KEYS, disabled, disabledBackspace, usedLetters]);
};

export default useKeyboardLayout;
