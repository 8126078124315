import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";

import s from "./Toasts.module.scss";

// In seconds; need to be the same as the CSS
const TOTAL_VISIBLE_TIME = 4;
const HIDE_TIME = 0.5;
let globalId = 0;

export type TProps = {
	onSetAddToastMessage: (func: TAddToastMessageFunc) => void;
};

export type TAddToastMessageFunc = (message: string) => void;

type TToastInfo = {
	id: string;
	text: string;
	timeShown: number;
	showing: boolean;
	hiding: boolean;
};

export const Toasts = ({ onSetAddToastMessage }: TProps): JSX.Element | null => {
	const [toasts, setToasts] = useState<TToastInfo[]>([]);

	const updateToasts = useCallback(() => {
		setToasts((oldToasts) => {
			const now = Date.now();
			const newToasts: TToastInfo[] = [];
			for (const toast of oldToasts) {
				const newToast = { ...toast };
				const toastDestroyTime = toast.timeShown + TOTAL_VISIBLE_TIME * 1000;
				const toastHideTime = toastDestroyTime - HIDE_TIME * 1000;

				// Destroys
				if (now >= toastDestroyTime) {
					continue;
				}

				// Shows
				if (!newToast.showing) {
					newToast.showing = true;
				}

				// Hides
				if (!newToast.hiding && now >= toastHideTime) {
					newToast.hiding = true;
				}

				newToasts.push(newToast);
			}
			return newToasts;
		});
	}, []);

	const handleAddToastMessage = useCallback<TAddToastMessageFunc>(
		(message) => {
			setToasts((oldToasts) => {
				const newToast = {
					id: `message-${++globalId}`,
					text: message,
					timeShown: Date.now(),
					showing: false,
					hiding: false,
				};
				return [...oldToasts, newToast];
			});
			setTimeout(updateToasts, 0);
			setTimeout(updateToasts, (TOTAL_VISIBLE_TIME - HIDE_TIME) * 1000);
			setTimeout(updateToasts, TOTAL_VISIBLE_TIME * 1000);
		},
		[updateToasts],
	);

	useEffect(() => {
		onSetAddToastMessage(() => handleAddToastMessage);
	}, [handleAddToastMessage, onSetAddToastMessage]);

	if (toasts.length === 0) {
		return null;
	}

	return (
		<div className={s.main}>
			{toasts.map((toast) => {
				const classes = cx(s.toast, toast.showing ? s.showing : undefined, toast.hiding ? s.hiding : undefined);
				return (
					<div key={toast.id} className={classes}>
						<div className={s.inner}>{toast.text}</div>
					</div>
				);
			})}
		</div>
	);
};
