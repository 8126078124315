import Prando from "prando";

import { useMemo } from "react";
import { TSolution } from "./useSolution";
import { isConsonant, isVowel, uniqueLetters } from "../utils/AlphabetUtils";
import { shuffle } from "../utils/ArrayUtils";

export enum SymbolShape {
	Circle = "Circle",
	Square = "Square",
}

export type TSymbolList = string[];
export type TSymbolLetterMap = Record<string, string>; // symbol id : letter
export type TSymbolStyleMap = Record<string, TSymbolStyle>; // symbol id : style
export type TSymbolLetterList = Array<[string, string]>;
export type TSymbolSolutionField = TSymbolList[];
export type TSymbolStyle = {
	color: string;
	shape: SymbolShape;
};
export type TUseSymbols = {
	field: TSymbolSolutionField; // Which symbol id in each cell
	mapping: TSymbolLetterMap; // symbol id : letter mapping
	styles: TSymbolStyleMap; // symbol id :: style mapping
};

export const COLORS = [
	"#006321",
	"#05c697",
	"#353071",
	"#59797c",
	"#7dc142",
	"#8560bf",
	"#86c9ea",
	"#872155",
	"#bd4825",
	"#dbcb78",
	"#df9119",
	"#e869bb",
];

const createConsonantSymbolId = (index: number): string => {
	return `c${index}`;
};

const createVowelSymbolId = (index: number): string => {
	return `v${index}`;
};

const symbolIdForLetter = (letter: string, vowels: string[], consonants: string[]): string => {
	return isVowel(letter)
		? createVowelSymbolId(vowels.indexOf(letter))
		: createConsonantSymbolId(consonants.indexOf(letter));
};

/**
 * Based on a theme and a solution, give the symbols that should be displayed
 */
const useSymbols = (dayIndex: number, words: TSolution["words"]): Readonly<TUseSymbols> => {
	const rng = useMemo<Prando>(() => {
		const seed = 1 + dayIndex * 1234;
		const r = new Prando(seed);
		r.skip(4);
		const newSeed = r.next(1, 200000);
		return new Prando(newSeed);
	}, [dayIndex]);

	const colors = useMemo((): [string[], string[]] => {
		return [shuffle(rng, COLORS), shuffle(rng, COLORS)];
	}, [rng]);

	const fieldMapping = useMemo<Pick<TUseSymbols, "field" | "mapping" | "styles">>(() => {
		const letters = uniqueLetters(words);
		const allVowels = letters.filter(isVowel);
		const allConsonants = letters.filter(isConsonant);

		const field = words.map((word) => word.split("").map((l) => symbolIdForLetter(l, allVowels, allConsonants)));

		const mapping: TUseSymbols["mapping"] = {};
		const styles: TUseSymbols["styles"] = {};
		if (allConsonants.length > colors[1].length) {
			console.error(
				`[Symbols] Error! Needed ${allConsonants.length} colors, but only ${colors[1].length} were available!`,
			);
		}

		// Use any of the colors for consonants or vowels
		allConsonants.forEach((l, i) => {
			const symbolId = symbolIdForLetter(l, allVowels, allConsonants);
			mapping[symbolId] = l;
			styles[symbolId] = {
				color: colors[0][i % colors[0].length],
				shape: SymbolShape.Square,
			};
		});

		allVowels.forEach((l, i) => {
			const symbolId = symbolIdForLetter(l, allVowels, allConsonants);
			mapping[symbolId] = l;
			styles[symbolId] = {
				color: colors[1][i % colors[1].length],
				shape: SymbolShape.Circle,
			};
		});

		return {
			field,
			mapping,
			styles,
		};
	}, [words, colors]);

	return useMemo<TUseSymbols>(
		() => ({
			...fieldMapping,
		}),
		[fieldMapping],
	);
};

export default useSymbols;
