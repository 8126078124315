import { useCallback, useMemo } from "react";

import { TSymbolLetterList, TSymbolList } from "./useSymbols";
import { cleanupPersistentData, usePersistentIndexedState } from "./usePersistentState";
import { DATA_VERSION } from "../data/Constants";

type TGuesses = {
	right: TSymbolList;
	wrong: TSymbolLetterList;
};

export type TUseGuesses = {
	right: Readonly<TGuesses["right"]>;
	wrong: Readonly<TGuesses["wrong"]>;
	markRight: (symbol: string) => void;
	markWrong: (symbol: string, letter: string) => void;
};

const DEFAULT_STATE = (): TGuesses => {
	return {
		right: [],
		wrong: [],
	};
};

const listWithString = (list: string[], newItem: string): string[] => {
	if (!list.includes(newItem)) {
		return [...list, newItem];
	} else {
		return list;
	}
};

const listWithTuple = (list: Array<[string, string]>, newItem: [string, string]): Array<[string, string]> => {
	if (!list.some(([a, b]) => newItem[0] === a && newItem[1] === b)) {
		return [...list, newItem];
	} else {
		return list;
	}
};

// TODO: remove later
cleanupPersistentData("game-guesses-v", DATA_VERSION - 1);

/**
 * Controls what guesses (symbols:letter matching) the user has "checked" against so far
 */
const useGuesses = (dayIndex: number): Readonly<TUseGuesses> => {
	const [guesses, setGuesses] = usePersistentIndexedState<TGuesses>(
		`game-guesses-v${DATA_VERSION}`,
		dayIndex,
		DEFAULT_STATE,
	);

	const markRight = useCallback(
		(symbol: string) => {
			setGuesses((oldGuesses) => ({
				right: listWithString(oldGuesses.right, symbol),
				wrong: oldGuesses.wrong.filter(([s]) => s !== symbol),
			}));
		},
		[setGuesses],
	);

	const markWrong = useCallback(
		(symbol: string, letter: string) => {
			setGuesses((oldGuesses) => ({
				right: oldGuesses.right,
				wrong: listWithTuple(oldGuesses.wrong, [symbol, letter]),
			}));
		},
		[setGuesses],
	);

	return useMemo<TUseGuesses>(
		() => ({
			...guesses,
			markRight,
			markWrong,
		}),
		[guesses, markRight, markWrong],
	);
};

export default useGuesses;
