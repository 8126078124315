import React, { useCallback, useEffect, useRef, useState } from "react";

import { Key } from "./Key";
import { FieldCell } from "./FieldCell";
import { TSymbolStyleMap } from "../hooks/useSymbols";

import s from "./TextInstructions.module.scss";

export type TProps = {
	className?: string;
	onPressButton?: () => void;
	symbolStyles: TSymbolStyleMap;
};

export const TextInstructions = ({ className, onPressButton, symbolStyles }: TProps): JSX.Element => {
	const [consonant, setConsonant] = useState<string | undefined>(undefined);
	const [vowel, setVowel] = useState<string | undefined>(undefined);
	const animationTimeout = useRef<number | NodeJS.Timeout>();
	const animationInterval = useRef<number | NodeJS.Timeout>();

	const flipConsonant = useCallback(() => {
		setConsonant((c) => (c ? undefined : "C"));
	}, []);

	const flipVowel = useCallback(() => {
		setVowel((v) => (v ? undefined : "A"));
	}, []);

	const flipBoth = useCallback(() => {
		setTimeout(flipConsonant, 0);
		setTimeout(flipVowel, 1000);
	}, []);

	useEffect(() => {
		animationTimeout.current = setTimeout(() => {
			flipBoth();
			animationInterval.current = setInterval(flipBoth, 4000);
		}, 2000);

		return () => {
			if (animationTimeout.current) {
				clearTimeout(animationTimeout.current);
			}
			if (animationInterval.current) {
				clearInterval(animationInterval.current);
			}
		};
	}, []);

	return (
		<div className={className}>
			<p>
				Each puzzle is five 5-letter words relating to the day's theme. The object of the game is to decode all five
				words.
			</p>
			<table className={s.table}>
				<tbody>
					<tr>
						<td>
							<FieldCell
								symbol={"c1"}
								disabled={true}
								symbolStyle={symbolStyles["c1"]}
								solutionLetter={""}
								enteredLetter={consonant}
							/>
						</td>
						<td>
							<FieldCell
								symbol={"a1"}
								disabled={true}
								symbolStyle={symbolStyles["v1"]}
								solutionLetter={""}
								enteredLetter={vowel}
							/>
						</td>
					</tr>
					<tr>
						<td>Consonants are squares</td>
						<td>Vowels are circles</td>
					</tr>
				</tbody>
			</table>
			<p>Use the keyboard to enter letters.</p>
			<p>You have three chances to “Check” your work. Click “Hint” to use a check and reveal one word.</p>
			<p>If all the letters are correct on or before your third check, you've won!</p>
			<p>Happy Solving!</p>
			<p className={s.start}>
				<Key label={"START"} onPress={onPressButton} isVeryVeryLarge={true} />
			</p>
		</div>
	);
};
