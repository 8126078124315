import { START_DATE_TIME } from "../data/Constants";

export const dateToId = (date: Date): string => {
	return date.toISOString().substring(0, 10);
};

export const dateToIndex = (date: Date): number => {
	const now = date.getTime();
	const diffTime = now - START_DATE_TIME.getTime();
	const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24);
	return diffDays;
};

export const indexToDate = (index: number): Date => {
	const dateTime = START_DATE_TIME.getTime() + index * 24 * 60 * 60 * 1000;
	return new Date(dateTime);
};
