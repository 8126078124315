import { useCallback, useMemo } from "react";

import { cleanupPersistentData, usePersistentIndexedState, usePersistentState } from "./usePersistentState";
import { CHANCES_PER_DAY, DATA_VERSION, HINTS_PER_DAY } from "../data/Constants";
import { dateToId, indexToDate } from "../utils/ArchiveDateUtils";

type TChanceHistory = {
	blanks: string[];
	wrongs: string[];
};

type TPersistentDayState = {
	chancesLeft: number;
	hasWon: boolean;
	hasWonOnGameDay: boolean;
	chancesUsed: TChanceHistory[];
	hintsLeft: number;
};

type TPersistentState = Record<string, TPersistentDayState>;

export type TDayScore = TPersistentDayState & {
	maxChances: number;
	hasLost: boolean;
	takeChance: (wrongSymbols: string[], blankSymbols: string[]) => void;
	useHint: () => void;
};

export type TGeneralScore = {
	currentWinStreak: number;
	bestWinStreak: number;
	totalGames: number;
	gamesWon: number;
	gamesLost: number;
	gamesWon3Stars: number;
	gamesWon2Stars: number;
	gamesWon1Stars: number;
};

export type TArchivedDayScore = {
	id: string;
	offset: number;
	index: number;
	date: Date;
	hasWon: boolean;
	hasWonOnGameDay: boolean;
	hasLost: boolean;
	hintsUsed: boolean;
	chancesLeft: number;
};

export type TUseScore = {
	day: TDayScore;
	general: TGeneralScore;
	archive: TArchivedDayScore[];
};

const DEFAULT_DAY_STATE: TPersistentDayState = {
	chancesLeft: CHANCES_PER_DAY,
	hasWon: false,
	hasWonOnGameDay: false,
	chancesUsed: [],
	hintsLeft: HINTS_PER_DAY,
};

// TODO: remove later
cleanupPersistentData("game-score-v", DATA_VERSION - 1);

/**
 * Counts the score of the user
 */
const useScore = (dayIndex: number, realDayIndex: number, isGameDay: boolean): Readonly<TUseScore> => {
	const dataKey = `game-score-v${DATA_VERSION}`;
	const [dayState, setDayState] = usePersistentIndexedState<TPersistentDayState>(dataKey, dayIndex, DEFAULT_DAY_STATE);
	const [realDayState] = usePersistentIndexedState<TPersistentDayState>(dataKey, realDayIndex, DEFAULT_DAY_STATE);

	// A bit of a hack to read data for all previous games
	const [generalState] = usePersistentState<TPersistentState>(dataKey, {});
	const streakState = useMemo<Pick<TGeneralScore, "currentWinStreak" | "bestWinStreak">>(() => {
		let bestWinStreak = 0;
		let currentWinStreak = 0;
		let markedCurrentWinStreak = false;

		let currentDay = realDayIndex;

		// Lost on today's game: current streak is always 0
		const lostOnTodaysGame = realDayState.chancesLeft === 0;

		// Hasn't finished today's game: streak is from yesterday
		if (!realDayState.hasWonOnGameDay) {
			currentDay--;
		}

		let currentCountingStreak = 0;
		while (currentDay >= 0) {
			const won = generalState[currentDay]?.hasWonOnGameDay;

			if (won) {
				currentCountingStreak++;
			} else {
				if (currentCountingStreak > bestWinStreak) {
					bestWinStreak = currentCountingStreak;
				}

				if (!markedCurrentWinStreak && !lostOnTodaysGame) {
					currentWinStreak = currentCountingStreak;
					markedCurrentWinStreak = true;
				}

				currentCountingStreak = 0;
			}

			currentDay--;
		}

		if (!markedCurrentWinStreak && !lostOnTodaysGame) {
			currentWinStreak = currentCountingStreak;
		}

		return {
			currentWinStreak,
			bestWinStreak,
		};
	}, [realDayIndex, realDayState.hasWonOnGameDay, realDayState.chancesLeft, generalState]);

	const winStats = useMemo<
		Pick<TGeneralScore, "gamesWon" | "gamesLost" | "gamesWon3Stars" | "gamesWon2Stars" | "gamesWon1Stars">
	>(() => {
		const stats = {
			gamesWon: 0,
			gamesLost: 0,
			gamesWon3Stars: 0,
			gamesWon2Stars: 0,
			gamesWon1Stars: 0,
		};

		let currentDay = realDayIndex;
		while (currentDay >= 0) {
			const dayData = generalState[currentDay];
			if (dayData) {
				if (dayData.hasWon) {
					stats.gamesWon++;
					if (dayData.chancesLeft === 3) {
						stats.gamesWon3Stars++;
					} else if (dayData.chancesLeft === 2) {
						stats.gamesWon2Stars++;
					} else {
						stats.gamesWon1Stars++;
					}
				}

				if (dayData.chancesLeft === 0) {
					stats.gamesLost++;
				}
			}

			currentDay--;
		}

		return stats;
	}, [realDayIndex, generalState]);

	const takeChance = useCallback(
		(wrongSymbols: string[], blankSymbols: string[]) => {
			setDayState((oldState) => {
				const hasLostChance = wrongSymbols.length > 0;
				const hasWonGame = !hasLostChance && blankSymbols.length === 0;
				const newChancesUsed = [...oldState.chancesUsed, { blanks: blankSymbols, wrongs: wrongSymbols }];

				if (hasWonGame) {
					return { ...oldState, hasWon: true, hasWonOnGameDay: isGameDay, chancesUsed: newChancesUsed };
				} else {
					if (hasLostChance) {
						return { ...oldState, chancesLeft: Math.max(oldState.chancesLeft - 1, 0), chancesUsed: newChancesUsed };
					} else {
						return { ...oldState, chancesUsed: newChancesUsed };
					}
				}
			});
		},
		[isGameDay, setDayState],
	);

	const useHint = useCallback(() => {
		setDayState((oldState) => {
			return {
				...oldState,
				hintsLeft: Math.max(oldState.hintsLeft - 1, 0),
				chancesLeft: Math.max(oldState.chancesLeft - 1, 0),
			};
		});
	}, [setDayState]);

	const dayScore = useMemo<TDayScore>(() => {
		return {
			...dayState,
			maxChances: CHANCES_PER_DAY,
			maxHints: HINTS_PER_DAY,
			hasLost: dayState.chancesLeft === 0,
			hasHints: dayState.hintsLeft > 0,
			takeChance,
			useHint,
		};
	}, [
		dayState.chancesLeft,
		dayState.hasWon,
		dayState.hasWonOnGameDay,
		dayState.chancesUsed,
		dayState.hintsLeft,
		takeChance,
		useHint,
	]);

	const generalScore = useMemo<TGeneralScore>(() => {
		return {
			totalGames: realDayIndex + 1,
			...streakState,
			...winStats,
		};
	}, [realDayIndex, streakState, winStats]);

	const archiveScore = useMemo<TArchivedDayScore[]>(() => {
		const days: TArchivedDayScore[] = [];

		let currentDay = realDayIndex;
		while (currentDay >= 0) {
			const day = generalState[currentDay];
			const date = indexToDate(currentDay);
			days.push({
				id: dateToId(date),
				offset: currentDay - realDayIndex,
				index: currentDay,
				date,
				hasWon: day?.hasWon,
				hasWonOnGameDay: day?.hasWonOnGameDay,
				hasLost: day?.chancesLeft === 0,
				hintsUsed: day?.hintsLeft < HINTS_PER_DAY,
				chancesLeft: day?.chancesLeft ?? 0,
			});

			currentDay--;
		}

		return days;
	}, [generalState]);

	return useMemo<TUseScore>(() => {
		return {
			day: dayScore,
			general: generalScore,
			archive: archiveScore,
		};
	}, [dayScore, generalScore, archiveScore]);
};

export default useScore;
