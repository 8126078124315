import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";

import MaterialIcons from "./MaterialIcons";

import s from "./Dialog.module.scss";

export type TProps = {
	title: string;
	visible?: boolean;
	onClose?: () => void;
};

enum Status {
	Hidden = "hidden",
	Showing = "showing",
	Shown = "shown",
	Hiding = "hiding",
}

export const Dialog = ({ children, title, visible, onClose }: React.PropsWithChildren<TProps>): JSX.Element | null => {
	const prevVisible = useRef(!!visible);
	const mainAnimationRef = useRef<HTMLDivElement>(null);
	const [status, setStatus] = useState(visible ? Status.Shown : Status.Hidden);

	const classes = cx({
		[s.main]: true,
		[s.visibilityHidden]: status === Status.Hidden,
		[s.visibilityShowing]: status === Status.Showing,
		[s.visibilityShown]: status === Status.Shown,
		[s.visibilityHiding]: status === Status.Hiding,
	});

	const handleMainAnimationEnd = useCallback((e: React.AnimationEvent<HTMLDivElement>) => {
		if (e.target === mainAnimationRef.current) {
			setStatus((s) => {
				if (s === Status.Hiding) {
					return Status.Hidden;
				} else if (s === Status.Showing) {
					return Status.Shown;
				} else {
					return s;
				}
			});
		}
	}, []);

	useEffect(() => {
		if (visible !== prevVisible.current) {
			prevVisible.current = !!visible;
			if (visible) {
				setStatus(Status.Showing);
			} else {
				setStatus(Status.Hiding);
			}
		}
	}, [visible]);

	if (status === Status.Hidden) {
		return null;
	}

	return (
		<div className={classes}>
			<div className={s.overlay} onClick={onClose} ref={mainAnimationRef} onAnimationEnd={handleMainAnimationEnd} />
			<div className={s.content}>
				<div className={s.header}>
					<div className={s.title}>{title}</div>
					<MaterialIcons.Close className={s.closeButton} onClick={onClose} />
				</div>
				<div className={s.body}>{children}</div>
			</div>
		</div>
	);
};
