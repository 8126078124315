import { useMemo } from "react";
import Prando from "prando";

import { uniqueLetters } from "../utils/AlphabetUtils";
import { NUM_WORDS } from "../data/Constants";

// TODO: we use raw-loader, which is deprecated. We should use asset modules if/when we eject.
import gamesFile from "!!raw-loader!./games.csv";

export type TSolution = {
	name: string;
	words: string[];
	// From 5 to 25 (5 words) or 6 to 30 (6 words)
	minDifficulty: number;
	maxDifficulty: number;
	difficulty: number;
};

type TSimpleSolution = Pick<TSolution, "name" | "words">;

type TGame = {
	name: string;
	words: string[];
	date?: string;
};

const parseCSVLine = (line: string): string[] => {
	if (line.indexOf('"') === -1) {
		// Simple line
		return line.split(",");
	}
	// Need to parse each field
	const results = [];
	let current = "";
	let quoted = false;
	for (const letter of line.split("")) {
		if (letter === '"') {
			quoted = !quoted;
		} else if (letter === "," && !quoted) {
			results.push(current);
			current = "";
		} else {
			current += letter;
		}
	}
	results.push(current);

	return results;
};

const readGames = (): TGame[] => {
	return gamesFile
		.split("\n")
		.filter((line) => line.length >= 5 && !line.trim().startsWith("#"))
		.map((line) => {
			const l = parseCSVLine(line);
			return {
				name: l[0],
				words: l.slice(1, NUM_WORDS + 1),
				date: l[NUM_WORDS + 1],
			};
		});
};

const games: TGame[] = readGames();
const gamesWithDates: TGame[] = games.filter((g) => !!g.date && g.date.length > 0);
const gamesWithoutDates: TGame[] = games.filter((g) => !g.date || g.date === "");

// Pick a random game
const pickGame = (rng: Prando, dayId: string): TSimpleSolution => {
	// Find for a game with the specific day (yyyy-mm-dd), then specific day of year (mm-dd), then pick a random one
	const [year, month, day] = dayId.split("-");
	const game =
		gamesWithDates.find((g) => g.date === year + month + day) ??
		gamesWithDates.find((g) => g.date === month + day) ??
		rng.nextArrayItem(gamesWithoutDates);

	// TODO: somehow, keep the order of games that should come in a sequence

	return {
		name: game.name,
		words: game.words,
	};
};

const getSolutionStats = (
	words: string[],
): { minDifficulty: number; maxDifficulty: number; difficulty: number; numSoloLetters: number } => {
	const uniqueLettersChosen = uniqueLetters(words);
	const wordLetters = words.join("").split("");
	const minDifficulty = words[0].length;
	const maxDifficulty = minDifficulty * words.length;
	return {
		minDifficulty,
		maxDifficulty,
		difficulty: uniqueLettersChosen.length,
		numSoloLetters: uniqueLettersChosen.map((l) => wordLetters.filter((ll) => l === ll).length).filter((c) => c === 1)
			.length,
	};
};

/**
 * This contains the solution for the day (based on the day seed): the selected words, their category and assumed difficulty.
 */
const useSolution = (dayIndex: number, dayId: string): TSolution => {
	const rng = useMemo<Prando>(() => {
		const seed = 1 + dayIndex * 1000;
		const r = new Prando(seed);
		r.skip(4);
		const newSeed = r.next(1, 1000000);
		return new Prando(newSeed);
	}, [dayIndex]);

	const solution = useMemo<TSolution>(() => {
		// TODO: proper solution for picking words
		// TODO: maybe also judge by allowing more repeated letters
		rng.reset();

		const { name, words } = pickGame(rng, dayId);

		const stats = getSolutionStats(words);
		console.info("[Solution] New solution is", name, ":", words);
		console.info(
			`[Solution] Difficulty for solution is ${stats.difficulty} (of ${stats.minDifficulty}...${stats.maxDifficulty}), with ${stats.numSoloLetters} solo letters.`,
		);

		return {
			name,
			words,
			minDifficulty: stats.minDifficulty,
			maxDifficulty: stats.maxDifficulty,
			difficulty: stats.difficulty,
		};
	}, [rng, dayId]);

	return solution;
};

export default useSolution;
