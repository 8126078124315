import React from "react";
import cx from "classnames";

import { StarBoxIcon } from "./StarBoxIcon";

import s from "./Stars.module.scss";

export type TProps = {
	max: number;
	left: number;
	theme?: Theme;
	className?: string;
};

export enum Theme {
	Default = "default",
	CurrentColor = "currentColor",
}

export const Stars = ({ max, left, theme, className }: TProps): JSX.Element => {
	const finalTheme = theme ?? Theme.Default;
	return (
		<div className={cx(s.main, className)}>
			{new Array(max).fill(0).map((v, i) => {
				const isEmpty = i >= left;
				const classes = cx(
					s.starBox,
					isEmpty ? s.empty : undefined,
					finalTheme === Theme.Default ? s.themeDefault : s.themeCurrentColor,
				);
				return (
					<div key={`star-${i}`} className={classes}>
						<div className={s.starBoxIconFiller} />
						<StarBoxIcon className={s.starBoxIcon} />
					</div>
				);
			})}
		</div>
	);
};
