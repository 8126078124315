import { useCallback, useMemo, useState } from "react";

import { TSymbolSolutionField } from "./useSymbols";
import { NUM_WORDS, WORD_LENGTH } from "../data/Constants";

export type TSimplePosition = {
	row: number;
	col: number;
};

export type TPosition = {
	row: number;
	col: number;
	rows: number;
	cols: number;
	prevRow: number;
	prevCol: number;
	goToNext: () => TSimplePosition;
	goToNextWithoutMapping: (
		symbols: TSymbolSolutionField,
		hasLetterForSymbol: (symbol: string) => boolean,
		additionalSymbols: string[],
	) => TSimplePosition;
	goToPrev: () => TSimplePosition;
	set: (row: number, col: number) => void;
	goUp: () => TSimplePosition;
	goDown: () => TSimplePosition;
	goLeft: () => TSimplePosition;
	goRight: () => TSimplePosition;
};

const calculatePrevPosition = (position: TSimplePosition, cols: number): TSimplePosition => {
	if (position.col > 0) {
		return { row: position.row, col: position.col - 1 };
	} else if (position.row > 0) {
		return { row: position.row - 1, col: cols - 1 };
	} else {
		return position;
	}
};

const calculateNextPosition = (position: TSimplePosition, rows: number, cols: number): TSimplePosition => {
	if (position.col < cols - 1) {
		return { row: position.row, col: position.col + 1 };
	} else if (position.row < rows - 1) {
		return { row: position.row + 1, col: 0 };
	} else {
		return position;
	}
};

const calculateOffsetPosition = (
	position: TSimplePosition,
	offset: TSimplePosition,
	rows: number,
	cols: number,
): TSimplePosition => {
	return {
		col: Math.max(0, Math.min(position.col + offset.col, cols - 1)),
		row: Math.max(0, Math.min(position.row + offset.row, rows - 1)),
	};
};

const nextPositionWithoutMapping = (
	position: TSimplePosition,
	rows: number,
	cols: number,
	symbols: TSymbolSolutionField,
	hasLetterForSymbol: (symbol: string) => boolean,
	additionalSymbols: string[],
): TSimplePosition => {
	let newPosition = calculateNextPosition(position, rows, cols);
	while (true) {
		const symbolInNewPosition = symbols[newPosition.row][newPosition.col];
		const hasMapping = hasLetterForSymbol(symbolInNewPosition) || additionalSymbols.includes(symbolInNewPosition);
		if (!hasMapping) {
			// Finally found one, return
			return newPosition;
		}

		if (newPosition.row === rows - 1 && newPosition.col === cols - 1) {
			// At the end, return the next as a fallback
			return calculateNextPosition(position, rows, cols);
		}

		// Continue
		newPosition = calculateNextPosition(newPosition, rows, cols);
	}
};

const usePosition = (startRow: number, startCol: number): TPosition => {
	const rows = NUM_WORDS;
	const cols = WORD_LENGTH;
	const [row, setRow] = useState(startRow);
	const [col, setCol] = useState(startCol);

	const setPosition = useCallback((newRow: number, newCol: number) => {
		setRow(newRow);
		setCol(newCol);
	}, []);

	const goToNextWithoutMapping = useCallback(
		(
			symbols: TSymbolSolutionField,
			hasLetterForSymbol: (symbol: string) => boolean,
			additionalSymbols: string[],
		): TSimplePosition => {
			const newPosition = nextPositionWithoutMapping(
				{ row, col },
				rows,
				cols,
				symbols,
				hasLetterForSymbol,
				additionalSymbols,
			);
			setPosition(newPosition.row, newPosition.col);
			return newPosition;
		},
		[row, col, setPosition],
	);

	const goToNext = useCallback((): TSimplePosition => {
		const newPosition = calculateNextPosition({ row, col }, rows, cols);
		setPosition(newPosition.row, newPosition.col);
		return newPosition;
	}, [row, col, rows, cols, setPosition]);

	const prevPosition = useMemo((): TSimplePosition => {
		return calculatePrevPosition({ row, col }, cols);
	}, [row, col, cols]);

	const goToPrev = useCallback((): TSimplePosition => {
		setPosition(prevPosition.row, prevPosition.col);
		return prevPosition;
	}, [prevPosition, setPosition]);

	const goUp = useCallback((): TSimplePosition => {
		const newPosition = calculateOffsetPosition({ row, col }, { row: -1, col: 0 }, rows, cols);
		setPosition(newPosition.row, newPosition.col);
		return newPosition;
	}, [row, col, rows, cols, setPosition]);

	const goDown = useCallback((): TSimplePosition => {
		const newPosition = calculateOffsetPosition({ row, col }, { row: 1, col: 0 }, rows, cols);
		setPosition(newPosition.row, newPosition.col);
		return newPosition;
	}, [row, col, rows, cols, setPosition]);

	const goLeft = useCallback((): TSimplePosition => {
		const newPosition = calculateOffsetPosition({ row, col }, { row: 0, col: -1 }, rows, cols);
		setPosition(newPosition.row, newPosition.col);
		return newPosition;
	}, [row, col, rows, cols, setPosition]);

	const goRight = useCallback((): TSimplePosition => {
		const newPosition = calculateOffsetPosition({ row, col }, { row: 0, col: 1 }, rows, cols);
		setPosition(newPosition.row, newPosition.col);
		return newPosition;
	}, [row, col, rows, cols, setPosition]);

	const state = useMemo((): TPosition => {
		return {
			row,
			col,
			rows,
			cols,
			prevRow: prevPosition.row,
			prevCol: prevPosition.col,
			set: setPosition,
			goToNext,
			goToNextWithoutMapping,
			goToPrev,
			goUp,
			goDown,
			goLeft,
			goRight,
		};
	}, [row, col, setPosition, goToNextWithoutMapping]);

	return state;
};

export default usePosition;
