import React from "react";

export type TProps = {
	className?: string;
};

export const StarBoxIcon = ({ className }: TProps): JSX.Element => {
	return (
		<svg viewBox="0 0 56 56" className={className}>
			<path
				fill={"currentColor"}
				d="M56,0l0,56l-56,0l0,-56l56,0Zm-28.422,13.591l-3.667,8.782l-9.484,0.773c-0.184,0.015 -0.341,0.139 -0.398,0.315c-0.057,0.175 -0.003,0.367 0.137,0.487l7.219,6.201l-2.195,9.26c-0.043,0.18 0.027,0.367 0.176,0.475c0.149,0.109 0.348,0.117 0.506,0.021l8.128,-4.949l8.128,4.949c0.158,0.096 0.357,0.088 0.506,-0.021c0.149,-0.108 0.219,-0.295 0.176,-0.475l-2.195,-9.26l7.219,-6.201c0.14,-0.12 0.194,-0.312 0.137,-0.487c-0.057,-0.176 -0.214,-0.3 -0.398,-0.315l-9.484,-0.773l-3.667,-8.782c-0.071,-0.17 -0.238,-0.281 -0.422,-0.281c-0.184,0 -0.351,0.111 -0.422,0.281Z"
			/>
		</svg>
	);
};
